import React from 'react'
import { Grid, Row, Col, Typography } from 'antd'
import { StyledGillHeader } from './GillHeader.styles'
import { LayoutCentering } from '../../../components'

const GillHeader = ({ title }: { title: string }) => {
  const screens = Grid.useBreakpoint()

  return (
    <StyledGillHeader>
      <LayoutCentering>
        <Row align='middle'>
          <Col {...(screens.sm ? { flex: 'auto' } : { xs: 24 })}>
            <Typography.Title level={2}>{title}</Typography.Title>
          </Col>
        </Row>
      </LayoutCentering>
    </StyledGillHeader>
  )
}

export default GillHeader
