import React, { useState, useEffect } from 'react'
import { UpOutlined } from '@ant-design/icons'
import { Grid, Space, Button } from 'antd'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Menu } from './styled'
import { images } from '../../../assets'

const { useBreakpoint } = Grid

const menuList = [
  {
    name: 'Home',
    to: '/',
  },
  {
    name: 'Login',
    to: '/login',
  },
  {
    name: 'Create an Account',
    to: '/register',
  },
]

const menuLoggedList = [
  {
    name: 'Home',
    to: '/',
  },
  {
    name: 'Upcoming Webinars',
    to: '/upcoming-webinars',
  },
  {
    name: 'Previous Webinars',
    to: '/previous-webinars',
  },
  {
    name: 'In-Person Events',
    to: '/inperson-events',
  },
  {
    name: 'Learning Packs',
    to: '/learning-packs',
    icon: images.iconTablerBook2,
  },
  {
    name: 'Monthly Digest',
    to: '/monthly-digest',
    icon: images.iconTablerCalendarEvent,
  },
  {
    name: 'CPD Log',
    to: '/account/cpd-log',
  },
]

const MainMenu = ({
  onLogout,
  isLogged,
  handleMainMenu,
  isOpen,
}: {
  onLogout: () => void
  isLogged: boolean
  handleMainMenu: () => void
  isOpen: boolean
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const screens = useBreakpoint()

  const [dropdown, setDropdown] = useState<string>()

  const dropdownRef = useDetectClickOutside({ onTriggered: () => setDropdown(undefined) })

  useEffect(() => {
    if (dropdown) {
      setDropdown(undefined)
    }
  }, [screens])

  useEffect(() => {
    if (dropdown) {
      setDropdown(undefined)
    }
  }, [location.pathname])

  useEffect(() => {
    if (!isOpen) {
      setDropdown(undefined)
    }
  }, [isOpen])

  const menuAccountList = [
    {
      name: 'Overview',
      to: '/account/overview',
      icon: images.iconOverview,
    },
    {
      name: 'My Profile',
      to: '/account/profile',
      icon: images.iconProfile,
    },
    {
      name: 'Learning Hub History',
      to: '/account/learning-hub-history',
      icon: images.iconHistory,
    },
    {
      name: 'CPD Log',
      to: '/account/cpd-log',
      icon: images.iconLog,
    },
    {
      name: 'Log out',
      handler: onLogout,
      icon: images.iconLogout,
    },
  ]

  if (!isLogged) {
    if (screens.xxl) {
      return (
        <Space align='center' size='small'>
          {/* @ts-ignore */}
          <Button type='link-dark' onClick={() => navigate('/login')}>
            Login
          </Button>

          {/* @ts-ignore */}
          <Button type='ghost-dark' onClick={() => navigate('/register')} ghost>
            Create an account
          </Button>
        </Space>
      )
    }
    return (
      <Menu>
        <Menu.Inner>
          {menuList.map((item) => (
            <Menu.Item key={item.name} active={location.pathname === item.to}>
              <Link to={item.to}>{item.name}</Link>
            </Menu.Item>
          ))}
        </Menu.Inner>
      </Menu>
    )
  }

  return (
    <Menu ref={dropdownRef}>
      <Menu.Inner>
        {menuLoggedList.map((item) => {
          return (
            <Menu.Item key={item.name} active={location.pathname === item.to} onClick={handleMainMenu}>
              <Link to={item.to}>{item.name}</Link>
            </Menu.Item>
          )
        })}
      </Menu.Inner>

      <Menu.Inner>
        {screens.xxl ? (
          <Button
            // ref={dropdownRef}
            // @ts-ignore
            type='ghost-dark'
            onClick={() => setDropdown((current) => (current === 'my-account' ? undefined : 'my-account'))}
            ghost
          >
            My account <UpOutlined rotate={dropdown === 'my-account' ? 0 : 180} />
          </Button>
        ) : (
          <Menu.Dropdown.Toggle
            onClick={() => setDropdown((current) => (current === 'my-account' ? undefined : 'my-account'))}
          >
            <span>My account</span>
            <UpOutlined rotate={dropdown ? 0 : 180} />
          </Menu.Dropdown.Toggle>
        )}

        <Menu.Dropdown visible={dropdown === 'my-account'}>
          {menuAccountList.map((item) => (
            <Menu.Dropdown.Item key={item.name} onClick={handleMainMenu}>
              <Menu.Dropdown.Icon src={item.icon} />

              {item.to ? (
                <Link to={item.to}>{item.name}</Link>
              ) : (
                <Menu.Link onClick={item.handler}>{item.name}</Menu.Link>
              )}
            </Menu.Dropdown.Item>
          ))}
        </Menu.Dropdown>
      </Menu.Inner>
    </Menu>
  )
}

export default MainMenu
