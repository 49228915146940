import React, { useState } from 'react'
import { IconCalendarEvent, IconDownload, IconFile } from '@tabler/icons-react'
import { Modal, Pagination } from 'antd'
import clsx from 'clsx'
import { format } from 'date-fns'
import { Document, Page, pdfjs } from 'react-pdf'
import { ReactSVG } from 'react-svg'
import styled from 'styled-components'
import { images } from '../../../assets'
import { storage } from '../../../helpers'
import useBreakpoint from '../../../hooks/useBreakpoint'
import Loader from '../Loader'

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

const TypeIcon = styled.div<{ $type: string }>`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${({ $type }) => {
    switch ($type) {
      case 'pdf': {
        return '#7CCDCA'
      }
      case 'document': {
        return '#76AED6'
      }
      case 'powerpoint': {
        return '#EE8175'
      }
      default: {
        return '#FFFFFF'
      }
    }
  }};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0;
  flex-shrink: 0;

  svg {
    width: 28px;
    height: 28px;
    stroke: #fff;
  }
`

const PDF_OPTIONS = {
  httpHeaders: { Authorization: `Bearer ${storage.getItem('token')}` },
}

const FileListItem = ({ file }: { file: any }) => {
  const [openModal, setOpenModal] = useState(false)
  const [numPages, setNumPages] = useState<number>()
  const [pageNumber, setPageNumber] = useState(1)
  const breakpoint = useBreakpoint()

  return (
    <div className='border-grey-2 rounded-[8px] border border-solid bg-white p-[20px]'>
      <div className='mb-[20px] flex items-center gap-[16px]'>
        <TypeIcon $type={file.type}>
          {file.type === 'pdf' && <ReactSVG src={images.iconTablerFileTypePdf} />}
          {file.type === 'document' && <ReactSVG src={images.iconTablerFileTypeDoc} />}
          {file.type === 'powerpoint' && <ReactSVG src={images.iconTablerFileTypePpt} />}
        </TypeIcon>
        <p className='text-primary !m-0 text-[18px] font-semibold xl:text-[20px]'>{file.name}</p>
      </div>
      <div className='font-secondary text-[14px]' dangerouslySetInnerHTML={{ __html: file.description }} />
      <div className='bg-grey-1 mt-[28px] items-center justify-between rounded-[8px] px-[16px] py-[14px] xl:flex'>
        <ul className='!m-0 flex list-none gap-[40px]'>
          {[
            {
              icon: <IconFile size={16} />,
              label: 'Size',
              value: file.size,
            },
            {
              icon: <IconCalendarEvent size={16} />,
              label: 'Date',
              value: format(new Date(file.date), 'do MMMM yyyy'),
            },
          ].map((item) => (
            <li>
              <div className='mb-[4px] flex items-center'>
                <div className='text-solid-red mr-[4px]'>{item.icon}</div>
                <p className='text-grey-3 !m-0 mt-[4px] text-[10px] font-medium uppercase'>{item.label}</p>
              </div>
              <p className='!m-0 text-[14px] font-medium'>{item.value}</p>
            </li>
          ))}
        </ul>
        <div className='mt-[16px] xl:mt-0'>
          <button type='button' className='btn-primary-outline w-full xl:w-auto' onClick={() => setOpenModal(true)}>
            Open <IconDownload size={18} />
          </button>
        </div>
      </div>

      <Modal
        className='ant-modal-header-no-padding ant-modal-full'
        open={openModal}
        onCancel={() => setOpenModal(false)}
        centered
        footer={null}
        width='100%'
      >
        <Document
          className={clsx('justify-center text-center xl:flex', {
            '[&>div>canvas]:!h-auto [&>div>canvas]:!w-[100%]': !breakpoint.xlAndUp,
          })}
          file={`${import.meta.env.REACT_APP_BASE_URL}/files/${file.id}/content.pdf`}
          options={PDF_OPTIONS}
          onLoadSuccess={(document) => setNumPages(document.numPages)}
          loading={<Loader />}
        >
          <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />
          {breakpoint.xlAndUp && (
            <Page pageNumber={pageNumber + 1} renderTextLayer={false} renderAnnotationLayer={false} />
          )}
        </Document>
        <Pagination
          simple
          pageSize={breakpoint.xlAndUp ? 2 : 1}
          current={pageNumber}
          total={numPages}
          onChange={(page) => setPageNumber(page)}
          hideOnSinglePage
          style={{ textAlign: 'center', marginTop: 16 }}
        />
      </Modal>
    </div>
  )
}

export default FileListItem
