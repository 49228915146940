import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Grid, Row, Col, Typography, Image } from 'antd'
import styled from 'styled-components'
import ModalRequestAccess from './components/ModalRequestAccess'
import TextImage from './images/text-image.jpg'
import { Webinars } from './styled'
import { useDocumentTitle, useShallowEqualSelector } from '../../../hooks'
import { api } from '../../../services'
import { theme } from '../../../styles'
import { LayoutCentering, Ellipse } from '../../components'
import CallToAction from '../../components/CallToAction/CallToAction'
import FileListItem from '../../components/FileListItem/FileListItem'
import GillHeader from '../GillSteelPage/components/GillHeader'

const { useBreakpoint } = Grid

const FilesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const TechnicalLearningPacks = () => {
  const screens = useBreakpoint()
  const [requestModal, setRequestModal] = useState(false)
  const { profileDetails } = useShallowEqualSelector((state: any) => state.auth)

  const query = useQuery({
    queryKey: ['learning-packs'],
    queryFn: async () => {
      const response = await api.get('/files')

      return response.data.data
    },
  })

  useDocumentTitle('Learning Packs')

  return (
    <Webinars>
      <Webinars.CircleGreen>
        <Ellipse type='circle' color={theme.colors.green[200]} size={15.3} />
      </Webinars.CircleGreen>

      <Webinars.SemiRing>
        <Ellipse type='semiring' color={theme.colors.yellow[200]} size={25} rotate={-45} />
      </Webinars.SemiRing>

      <Webinars.SemiCircle>
        <Ellipse type='semicircle' color={theme.colors.lightBlue[200]} size={13.3} rotate={-45} />
      </Webinars.SemiCircle>

      <GillHeader title='Learning Packs' />

      <LayoutCentering maxWidth='910px'>
        <Row gutter={[32, 32]} wrap={!screens.xl} style={{ marginBottom: 32 }}>
          <Col {...(screens.xl ? { flex: '377px' } : { xs: 24 })} style={{ textAlign: 'center' }}>
            <Image src={TextImage} alt='Learning Packs' preview={false} />
          </Col>

          <Col {...(screens.xl ? { flex: 'auto' } : { xs: 24 })}>
            <Typography style={{ padding: '0 15px', fontSize: 14, marginBottom: 40 }}>
              <Typography.Paragraph strong style={{ fontSize: 20 }}>
                The Learning Packs have been compiled by the award-winning, experienced, and trusted solicitor - Gill
                Steel.
              </Typography.Paragraph>

              <Typography.Paragraph>
                Created for practitioners in England & Wales, the Learning Packs are designed for those seeking to
                increase knowledge and awareness on varying topics in Wills, Probate, Elderly and vulnerable client law,
                Tax and Trusts. Each module offers in depth sections with links to articles and relevant advice and
                legislation (reviewed and updated in January 2024), along with additional resources, and a multiple
                choice question and answer section to test your knowledge.
              </Typography.Paragraph>
            </Typography>
          </Col>
        </Row>

        {Object.keys(profileDetails).length > 0 && !profileDetails.has_access_to_restricted_files && (
          <CallToAction
            title="Get access to all TLP's"
            content='We are pleased to offer our Technical Learning Packs at no cost to clients of Estate Research PC Ltd. To find out if you qualify for this exclusive benefit, please reach out to us. Our team is ready to assist you in determining your eligibility and answering any questions you may have.'
            button={{
              title: 'Contact Us',
              onClick: () => setRequestModal(true),
            }}
          />
        )}

        <FilesList style={{ marginTop: 32 }}>
          {query.data?.map((item: any) => <FileListItem key={item.id} file={item} />)}
        </FilesList>
      </LayoutCentering>

      <ModalRequestAccess open={requestModal} onCancel={() => setRequestModal(false)} />
    </Webinars>
  )
}

export default TechnicalLearningPacks
