import { createBreakpoint } from 'react-use'

const BREAKPOINTS = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
} as const

const useBreakpoint = () => {
  const breakpoint = createBreakpoint(BREAKPOINTS)
  const currentBreakpoint = breakpoint()

  return {
    current: currentBreakpoint,

    sm: currentBreakpoint === 'sm',
    md: currentBreakpoint === 'md',
    lg: currentBreakpoint === 'lg',
    xl: currentBreakpoint === 'xl',
    '2xl': currentBreakpoint === '2xl',

    smAndUp: ['sm', 'md', 'lg', 'xl', '2xl'].includes(currentBreakpoint),
    mdAndUp: ['md', 'lg', 'xl', '2xl'].includes(currentBreakpoint),
    lgAndUp: ['lg', 'xl', '2xl'].includes(currentBreakpoint),
    xlAndUp: ['xl', '2xl'].includes(currentBreakpoint),
    '2xlAndUp': ['2xl'].includes(currentBreakpoint),
  }
}

export default useBreakpoint
