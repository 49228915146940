import React from 'react'
import { Col, Grid, Image, Row } from 'antd'
import { Link } from 'react-router-dom'
import AwardImage from './images/badge-award.png'
import Award2024Image from './images/badge-awards-2024.png'
import WomanImage from './images/lesley-king.png'
import Award2024Logo from './images/logo-awards-2024.png'
import AwardLogo from './images/logo-awards.png'
import { StyledBox, StyledContainer, StyledContent, StyledCopy, StyledLogo } from './styled'
import { useShallowEqualSelector } from '../../../../../hooks'

const { useBreakpoint } = Grid

const MainHighlights = () => {
  const screens = useBreakpoint()
  const { isLogged } = useShallowEqualSelector((state: any) => state.auth)

  return (
    <StyledContainer>
      <StyledContent>
        <Row gutter={screens.lg ? [32, 32] : [16, 16]}>
          <Col md={24} xl={12}>
            <StyledBox>
              <Row wrap={false} gutter={40} className='flex items-center'>
                <Col>
                  <StyledCopy>
                    <p>
                      We are thrilled to announce that Professor Lesley King will be joining the Estate Research
                      Learning Hub to continue the Monthly Digest following the retirement of Gill Steel.
                    </p>
                    <p>
                      <Link to={isLogged ? '/monthly-digest' : '/login'}>Click here to read</Link> the latest Monthly
                      Digest from Lesley.
                    </p>
                  </StyledCopy>
                </Col>
                {screens.xl && (
                  <Col>
                    <Image src={WomanImage} preview={false} style={{ marginBottom: '-2rem', width: 450 }} />
                  </Col>
                )}
              </Row>
            </StyledBox>
          </Col>
          <Col md={24} xl={12}>
            <StyledBox>
              <Row wrap={!screens.lg} gutter={40}>
                <Col xs={24} flex='1'>
                  <StyledLogo src={AwardLogo} preview={false} />
                  <StyledCopy>
                    Estate Research Learning Hub wins the Best use of Technology award at the The British Wills &
                    Probate Awards 2023
                  </StyledCopy>
                </Col>
                <Col xs={24} flex={screens.sm ? 'none' : 'auto'} style={{ textAlign: 'center' }}>
                  <Image src={AwardImage} preview={false} style={{ width: 273, marginTop: screens.xl ? 0 : 24 }} />
                </Col>
              </Row>
            </StyledBox>
          </Col>
          <Col md={24}>
            <StyledBox>
              <Row wrap={!screens.lg} gutter={40}>
                <Col xs={24} flex='1'>
                  <StyledLogo src={Award2024Logo} preview={false} />
                  <StyledCopy>
                    Estate Research has been honoured with the <b>Probate Research Firm of the Year award</b> at the{' '}
                    <b>British Wills and Probate Awards 2024</b>. This accolade celebrates our unwavering dedication to
                    delivering high-quality research and exceptional service to both clients and beneficiaries
                    throughout the year.
                  </StyledCopy>
                </Col>
                <Col xs={24} flex={screens.sm ? 'none' : 'auto'} style={{ textAlign: 'center' }}>
                  <Image src={Award2024Image} preview={false} style={{ width: 273, marginTop: screens.xl ? 0 : 24 }} />
                </Col>
              </Row>
            </StyledBox>
          </Col>
        </Row>
      </StyledContent>
    </StyledContainer>
  )
}

export default MainHighlights
